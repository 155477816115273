@import "./variables";
@import "./category";
@import "./checkout";
@import "./payment";

#btn-back-to-top {
  position: fixed;
  bottom: 20px;
  padding: 10px 15px;
  right: 20px;
  border-radius: 15px;
  background-color: $primary;
  &:hover {
    background-color: darken($primary, 10%) !important;
  }
  i {
    font-size: 1.2rem;
    padding-right: 0;
  }
}