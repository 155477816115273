// Theme colors
$primary: #34cfa3;
$secondary: #4dd5ae;
$light: #AFB2B6;
$light-secondary: #d0f5eb;
$dark: #000000;
$white: #ffffff;
$success: #a6d352;
$warning: #d3b552;
$danger: #d3526b;

// Typography
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
$font-family: "Bebas Neue", Sans-serif;