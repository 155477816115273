/* DATEPICKER */
.datepicker {
	padding: 1em;

	table tr td {
		width: 14%;
		height: 2em;
	}

	table tr td.active.active {
		background-color: $dark !important;
		background-image: none !important;
		color: $white;
	}
}

#products {
	background-color: $dark;
	font-family: $font-family;
	letter-spacing: 0.06em;

	/* HEADER */
	#header {
		background-color: $dark;

		h1 {
			color: $white;
			font-size: 6em;
			font-weight: 500;
			text-align: center;
			text-transform: uppercase;
		}

		form[name="search_date"] {
			width: 100%;
		}

		#searchbar {
			display: flex;
			align-items: center;
			background-color: rgba(0, 0, 0, 0.7);
			.cart_btn {
				position: relative;
				svg {
					height: 40px;
					width: auto;
					path {
						fill: #fff;
					}
				}
				.cart_count {
					position: absolute;
					bottom: 0;
					right: -13px;
					color: #fff;
					font-size: 15px;
				}
			}
		}

		#searchbar select,
		#searchbar input {
			background-color: $dark;
			color: $white;
			padding: 0.3em 5em 0.3em 0.4em;
			margin: 0.2rem 0.6rem;
			border-color: $white;
			border-radius: 0;
			font-weight: 500;
			width: 100%;
		}

		.btn-search {
			background-color: $primary;
			color: $white;
			padding: 0.4em 2em;
			border-radius: 50px;
			font-size: 1.4em;
			text-transform: uppercase;
			font-weight: 500;
			float: right;
		}
	}

	/* CONTENT */
	#content {

		.btn-category {
			background-color: $primary;
			color: $white;
			padding: 0.4em 2em;
			border-radius: 5px;
			border: none;
			font-size: 1.4em;
			text-transform: uppercase;
			font-weight: 500;
			transition: all .2s ease;

            &:hover, &.active {
                background-color: $secondary;
                opacity: 0.6;
                padding: 0.4em 3em;
                transition: all .2s ease;
            }
		}

		.category-name {
			font-size: 1.8rem;
			width: 100%;
			background-color: #000;
			color: #fff;
		}

		.product-content {
			margin: 2em 0;
			border: 1px solid $dark;

			.product-image {
				height: 100%;
				// border-left: 1px solid $dark;
				// border-top: 1px solid $dark;
				// border-bottom: 1px solid $dark;
			}

			.product-name {
				display: flex;
				align-items: center;
				background-color: $dark;
				color: $white;
				width: 85%;
				text-transform: uppercase;
				font-size: 1.6em;
				font-weight: 600;
			}

			.product-info {
				height: 100%;
				border: 1px solid $dark;
				padding: 0 10px;
				.product-sizes {
					display: grid;
					grid-gap: 1px;
					grid-template-columns: repeat(4, minmax(0, 1fr));
					grid-auto-rows: minmax(8rem, auto);
				}
			}

			.product-price {
				width: 15%;
				background-color: $primary;
				padding: 0.8rem;
				color: $dark;
				text-transform: uppercase;
				font-size: 2em;
				font-weight: 500;
			}

			.product-availible-size {
				display: flex;
				border: 1px solid $dark;
			}

			.product-size {
				background-color: $light;
				color: $dark;
				width: 60%;
				padding: 0.6em;
				display: grid;

				.rounded-pill {
					max-height: 2rem;
				}

				.pill {
					display: inline-block;
					width: 8px;
					height: 8px;
					background-color: $danger;
				}
			}

			.product-size .pill-good {
				background-color: $success;
			}
			.product-size .pill-medium {
				background-color: $warning;
			}
			.product-size .pill-bad {
				background-color: $danger;
			}

			.product-size-count {
				width: 40%;
				display: flex;
				justify-content: center;
				align-items: center;
				&.disabled {
					background-color: #8d8d8d;
				}
			}

			.product-size-count .product-size-count,
			.product-size-count .product-size-count {
				color: $secondary;
				font-size: 1.6em;
				font-weight: bold;
				padding: 1em 0.6em;
				cursor: pointer;
			}

			.product-size-count .product-size-count-text {
				font-size: 2.2em;
				font-weight: bold;
			}

			.product-price-info {
				background-color: $light-secondary;
				font-weight: 600;
				font-size: 1em;
				padding: 0.6em;
			}

			.product-option .option:first-child {
				border-right: 1px solid $dark;
			}

			.form-check-input {
				border-radius: 0;
			}

			.form-check-input:checked {
				background-color: $dark;
				border-color: $dark;
			}

			.product-option-img {
				height: 3.4rem;
				margin-right: 1em;
			}

			.product-option .product-checkbox {
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.product-option .form-check-label {
				cursor: pointer;
				padding-right: 1em;
			}

			.product-option .form-check-input {
				cursor: pointer;
				float: right;
			}

			.product-description {
				align-items: center;
				padding: 1em;
				border-top: 1px solid $dark;
			}

			.product-see-more {
				color: $dark;
				text-decoration: underline;
			}

			.product-technical-sheet {
				font-size: 1.6rem;
				text-transform: uppercase;
				color: $primary;
				text-decoration: underline;
			}
		}
	}
	/* RESPONSIVE */
	@media screen and (max-width: 1440px) {
		#content .product-info {
			height: auto;
			.product-sizes {
				grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
			}
		}
	}

	@media screen and (max-width: 1200px) {
		#content .product-content {
			height: auto;
		}

		#content .product-content .product-image {
			width: 100%;
			border-right: 1px solid #000;
		}

		#content .product-info {
			height: auto;
			.product-sizes {
				grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
			}
		}
	}

	@media screen and (max-width: 992px) {
		#header .btn-search {
			margin-top: 1rem;
		}
	}

	@media screen and (max-width: 768px) {
		#content .product-name {
			width: 70% !important;
		}

		#content .product-price {
			width: 30% !important;
		}

		#content .product-info {
			.product-sizes {
				grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
			}
		}

		#content .product-option .option:first-child {
			border: none;
		}
	}
}
